module.exports={
  "Energy actions": "Energy actions",
  "Show": "Show",
  "Apartments": "Apartments",
  "Heated area": "Heated area",
  "Constructed": "Constructed",
  "Ventilation type": "Ventilation type",
  "About Brf Energi": "About Brf Energi",
  "How it works": "How it works",
  "Sign in": "Sign in",
  "Menu": "Menu",
  "Close": "Close",
  "Learn about how we calculate energy performance": "Learn about how we calculate energy performance",
  "What's this?": "What's this?",
  "Energy action": {
    "one": "Energy action",
    "other": "Energy actions"
  },
  "Leave a comment": "Leave a comment",
  "Sign in with Metry": "Sign in with Metry",
  "COPYRIGHT": "Â© %s KTH Royal Institute of Technology",
  "Sign out": "Sign out",
  "ACTION_TYPE_1": "VÃ¤rme",
  "ACTION_TYPE_1_1": "SÃ¤nkt temperatur i allmÃ¤nna utrymmen (t.ex. garage, trapphus, kÃ¤llare)",
  "ACTION_TYPE_1_2": "SÃ¤nkt temperatur i lÃ¤genheter",
  "ACTION_TYPE_1_3": "Ãvervakning av returtemperaturer fÃ¶r fjÃ¤rrvÃ¤rme",
  "ACTION_TYPE_1_4": "Justering av vÃ¤rmesystemets instÃ¤llningar",
  "ACTION_TYPE_1_5": "Justering av placering/installation av sensorer fÃ¶r utomhustemperatur",
  "ACTION_TYPE_1_6": "Installation av system fÃ¶r Ã¥tervinning av frÃ¥nluft",
  "ACTION_TYPE_1_7": "Reparation av trasiga radiatortermostat",
  "ACTION_TYPE_1_8": "Byte av radiatorventiler",
  "ACTION_TYPE_1_9": "Luftning av radiatorer",
  "ACTION_TYPE_1_10": "TÃ¤tning runt dragiga fÃ¶nster eller dÃ¶rrar",
  "ACTION_TYPE_1_11": "TillÃ¤ggsisolering av tak eller vÃ¤ggar",
  "ACTION_TYPE_1_12": "TillÃ¤ggsisolering av varmvattenrÃ¶r",
  "ACTION_TYPE_1_13": "Byte av fÃ¶nster",
  "ACTION_TYPE_1_14": "Inglasning av balkonger",
  "ACTION_TYPE_1_x": "Ãvriga Ã¥tgÃ¤rder vÃ¤rme",
  "ACTION_TYPE_2": "Varmvatten",
  "ACTION_TYPE_2_1": "Individuell mÃ¤tning av varmvatten",
  "ACTION_TYPE_2_2": "Justering av tappvarmvattnets temperatur",
  "ACTION_TYPE_2_3": "Justering av varmvattnets cirkulationsflÃ¶de ",
  "ACTION_TYPE_2_x": "Ãvriga Ã¥tgÃ¤rder varmvatten",
  "ACTION_TYPE_3": "Ventilation",
  "ACTION_TYPE_3_1": "RengÃ¶ring ventilation (t.ex. rensning av luftintagsgaller)",
  "ACTION_TYPE_3_2": "Byte av ventilationsfilter",
  "ACTION_TYPE_3_3": "Kontroll av luftintag i lÃ¤genheter",
  "ACTION_TYPE_3_4": "Kontroll av flÃ¤ktar",
  "ACTION_TYPE_3_5": "Optimering av ventilationsfrekvens/-flÃ¶de",
  "ACTION_TYPE_3_6": "Justering av ventilationsschema fÃ¶r lokaler baserat pÃ¥ aktivitet",
  "ACTION_TYPE_3_7": "Justering av tilluftens temperatur",
  "ACTION_TYPE_3_8": "Optimering av tillfÃ¶rsel av friskluft till frÃ¥nluftsÃ¥tervinning ",
  "ACTION_TYPE_3_9": "Justering av trycksensorers placering och installation",
  "ACTION_TYPE_3_10": "GenomfÃ¶rd OVK (obligatorisk ventilationskontroll)",
  "ACTION_TYPE_3_x": "Ãvriga Ã¥tgÃ¤rder ventilation",
  "ACTION_TYPE_4": "Belysning",
  "ACTION_TYPE_4_1": "Byte till LED-belysning",
  "ACTION_TYPE_4_2": "Byte till nÃ¤rvarostyrd belysning",
  "ACTION_TYPE_4_3": "Kontroll av belysningssensorer (t.ex. att de inte Ã¤r trasiga eller Ã¶vertÃ¤ckta)",
  "ACTION_TYPE_4_4": "Sektionering av belysning (allt tÃ¤nds inte samtidigt)",
  "ACTION_TYPE_4_x": "Ãvriga Ã¥tgÃ¤rder belysning",
  "ACTION_TYPE_5": "TvÃ¤ttstuga",
  "ACTION_TYPE_5_1": "Installation av energieffektiva maskiner i tvÃ¤ttstuga",
  "ACTION_TYPE_5_2": "Omkoppling av tvÃ¤ttmaskiner till varmvatten",
  "ACTION_TYPE_5_3": "Installation av betalsystem i tvÃ¤ttstuga",
  "ACTION_TYPE_5_4": "Justering av ventilationsflÃ¶den i torkskÃ¥p",
  "ACTION_TYPE_5_x": "Ãvriga Ã¥tgÃ¤rder tvÃ¤ttstuga",
  "ACTION_TYPE_6": "Ãvrig el inkl. solceller",
  "ACTION_TYPE_6_1": "Optimering av temperaturer och tidsinstÃ¤llningar fÃ¶r elvÃ¤rmning av stuprÃ¶r, tak, trottoarer m.m.",
  "ACTION_TYPE_6_2": "Kontroll av sensorer fÃ¶r elvÃ¤rme",
  "ACTION_TYPE_6_3": "InternetÃ¶vervakning (DUC) av elvÃ¤rmning av stuprÃ¶r, tak, trottoarer m.m.",
  "ACTION_TYPE_6_4": "Larm kopplade till nÃ¤r internetÃ¶vervakning (DUC) identifierar av ovanliga vÃ¤rden",
  "ACTION_TYPE_6_5": "Installation av solceller",
  "ACTION_TYPE_6_6": "Kontroll av solcellssystem",
  "ACTION_TYPE_6_7": "Individuell mÃ¤tning av elanvÃ¤ndning",
  "ACTION_TYPE_6_x": "Ãvriga Ã¥tgÃ¤rder el",
  "VENTILATION_TYPE_FTX": "FTX (mekanisk frÃ¥n- och tilluft med Ã¥tervinning)",
  "VENTILATION_TYPE_FVP": "FVP (frÃ¥nluftsvÃ¤rmepump)",
  "VENTILATION_TYPE_F": "F (mekanisk frÃ¥nluftsventilation)",
  "VENTILATION_TYPE_FT": "FT (mekanisk frÃ¥n- och tilluftsventilation)",
  "VENTILATION_TYPE_S": "S (sjÃ¤lvdragsventilation)",
  "VENTILATION_TYPE_OTHER": "Annat",
  "Back to %s": "Back to %s",
  "Category": {
    "one": "Category",
    "other": "Categories"
  },
  "Date": "Date",
  "Cost": "Cost",
  "Description": "Description",
  "Save": "Save",
  "Edit energy action": "Edit energy action",
  "No comments yet": "No comments yet",
  "Energy use": "Energy use",
  "No consumtion data": "No consumtion data",
  "Show All Cooperatives": "Show All Cooperatives",
  "Find me": "Find me",
  "Edit": "Edit",
  "Charger for electric cars": "Charger for electric cars",
  "Energy production": "Energy production",
  "Energy representative": "Energy representative",
  "Consumption mapping": "Consumption mapping",
  "Energy management": "Energy management",
  "Belysningsutmaningen": "Belysningsutmaningen",
  "Has renewable energy production": "Has renewable energy production",
  "Designated Energyrepresentative": "Designated Energyrepresentative",
  "Energy consumption mapping": "Energy consumption mapping",
  "Goal oriented energy management": "Goal oriented energy management",
  "Part of belysningsutmaningen": "Part of belysningsutmaningen",
  "Shared laundry room": "Shared laundry room",
  "Garage": "Garage",
  "METRY_ERROR": "Something went wrong when signing you in. Please try again.",
  "Cooperative not found": "Cooperative not found",
  "Cannot read property 'toString' of undefined": "Cannot read property 'toString' of undefined",
  "Could not find page": "Could not find page",
  "Energy initiatives": "Energy initiatives",
  "No energy actions": "No energy actions",
  "Missing your cooperative?": "Missing your cooperative?",
  "Add your cooperative": "Add your cooperative",
  "Search and filter": "Search and filter",
  "Oops!": "Oops!",
  "An unforseen error has occured. It might be temporary and you could": "An unforseen error has occured. It might be temporary and you could",
  "try again": "try again",
  "or go to": "or go to",
  "the homepage": "the homepage",
  "An error has occured": "An error has occured"
}