module.exports={
  "Show": "Visa",
  "Heated area": "UppvÃ¤rmd area",
  "Number of apartments": "Antal lÃ¤genheter",
  "Year of construction": "ByggÃ¥r",
  "Ventilation type": "Ventilationstyp",
  "Energy action": {
    "one": "EnergiÃ¥tgÃ¤rd",
    "other": "EnergiÃ¥tgÃ¤rder"
  },
  "About Brf Energi": "Om Brf Energi",
  "How it works": "Hur funkar det?",
  "Sign in": "Logga in",
  "Menu": "Meny",
  "Close": "StÃ¤ng",
  "Leave a comment": "Skriv en kommentar",
  "Learn about how we calculate energy performance": "LÃ¤s mer om hur vi berÃ¤knar prestanda",
  "What's this?": "Vad Ã¤r detta?",
  "Sign out": "Logga ut",
  "Sign in with Metry": "Logga in med Metry",
  "Create an account": "Skapa konto",
  "COPYRIGHT": "Â© %s KTH Kungliga Tekniska HÃ¶gskolan",
  "ACTION_TYPE_1": "VÃ¤rme",
  "ACTION_TYPE_1_1": "SÃ¤nkt temperatur i allmÃ¤nna utrymmen (t.ex. garage, trapphus, kÃ¤llare)",
  "ACTION_TYPE_1_2": "SÃ¤nkt temperatur i lÃ¤genheter",
  "ACTION_TYPE_1_3": "Ãvervakning av returtemperaturer fÃ¶r fjÃ¤rrvÃ¤rme",
  "ACTION_TYPE_1_4": "Justering av vÃ¤rmesystemets instÃ¤llningar",
  "ACTION_TYPE_1_5": "Justering av placering/installation av sensorer fÃ¶r utomhustemperatur",
  "ACTION_TYPE_1_6": "Installation av system fÃ¶r Ã¥tervinning av frÃ¥nluft",
  "ACTION_TYPE_1_7": "Reparation av trasiga radiatortermostat",
  "ACTION_TYPE_1_8": "Byte av radiatorventiler",
  "ACTION_TYPE_1_9": "Luftning av radiatorer",
  "ACTION_TYPE_1_10": "TÃ¤tning runt dragiga fÃ¶nster eller dÃ¶rrar",
  "ACTION_TYPE_1_11": "TillÃ¤ggsisolering av tak eller vÃ¤ggar",
  "ACTION_TYPE_1_12": "TillÃ¤ggsisolering av varmvattenrÃ¶r",
  "ACTION_TYPE_1_13": "Byte av fÃ¶nster",
  "ACTION_TYPE_1_14": "Inglasning av balkonger",
  "ACTION_TYPE_1_x": "Ãvriga Ã¥tgÃ¤rder vÃ¤rme",
  "ACTION_TYPE_2": "Varmvatten",
  "ACTION_TYPE_2_1": "Individuell mÃ¤tning av varmvatten",
  "ACTION_TYPE_2_2": "Justering av tappvarmvattnets temperatur",
  "ACTION_TYPE_2_3": "Justering av varmvattnets cirkulationsflÃ¶de ",
  "ACTION_TYPE_2_x": "Ãvriga Ã¥tgÃ¤rder varmvatten",
  "ACTION_TYPE_3": "Ventilation",
  "ACTION_TYPE_3_1": "RengÃ¶ring ventilation (t.ex. rensning av luftintagsgaller)",
  "ACTION_TYPE_3_2": "Byte av ventilationsfilter",
  "ACTION_TYPE_3_3": "Kontroll av luftintag i lÃ¤genheter",
  "ACTION_TYPE_3_4": "Kontroll av flÃ¤ktar",
  "ACTION_TYPE_3_5": "Optimering av ventilationsfrekvens/-flÃ¶de",
  "ACTION_TYPE_3_6": "Justering av ventilationsschema fÃ¶r lokaler baserat pÃ¥ aktivitet",
  "ACTION_TYPE_3_7": "Justering av tilluftens temperatur",
  "ACTION_TYPE_3_8": "Optimering av tillfÃ¶rsel av friskluft till frÃ¥nluftsÃ¥tervinning ",
  "ACTION_TYPE_3_9": "Justering av trycksensorers placering och installation",
  "ACTION_TYPE_3_10": "GenomfÃ¶rd OVK (obligatorisk ventilationskontroll)",
  "ACTION_TYPE_3_x": "Ãvriga Ã¥tgÃ¤rder ventilation",
  "ACTION_TYPE_4": "Belysning",
  "ACTION_TYPE_4_1": "Byte till LED-belysning",
  "ACTION_TYPE_4_2": "Byte till nÃ¤rvarostyrd belysning",
  "ACTION_TYPE_4_3": "Kontroll av belysningssensorer (t.ex. att de inte Ã¤r trasiga eller Ã¶vertÃ¤ckta)",
  "ACTION_TYPE_4_4": "Sektionering av belysning (allt tÃ¤nds inte samtidigt)",
  "ACTION_TYPE_4_x": "Ãvriga Ã¥tgÃ¤rder belysning",
  "ACTION_TYPE_5": "TvÃ¤ttstuga",
  "ACTION_TYPE_5_1": "Installation av energieffektiva maskiner i tvÃ¤ttstuga",
  "ACTION_TYPE_5_2": "Omkoppling av tvÃ¤ttmaskiner till varmvatten",
  "ACTION_TYPE_5_3": "Installation av betalsystem i tvÃ¤ttstuga",
  "ACTION_TYPE_5_4": "Justering av ventilationsflÃ¶den i torkskÃ¥p",
  "ACTION_TYPE_5_x": "Ãvriga Ã¥tgÃ¤rder tvÃ¤ttstuga",
  "ACTION_TYPE_6": "Ãvrig el inkl. solceller",
  "ACTION_TYPE_6_1": "Optimering av temperaturer och tidsinstÃ¤llningar fÃ¶r elvÃ¤rmning av stuprÃ¶r, tak, trottoarer m.m.",
  "ACTION_TYPE_6_2": "Kontroll av sensorer fÃ¶r elvÃ¤rme",
  "ACTION_TYPE_6_3": "InternetÃ¶vervakning (DUC) av elvÃ¤rmning av stuprÃ¶r, tak, trottoarer m.m.",
  "ACTION_TYPE_6_4": "Larm kopplade till nÃ¤r internetÃ¶vervakning (DUC) identifierar av ovanliga vÃ¤rden",
  "ACTION_TYPE_6_5": "Installation av solceller",
  "ACTION_TYPE_6_6": "Kontroll av solcellssystem",
  "ACTION_TYPE_6_7": "Individuell mÃ¤tning av elanvÃ¤ndning",
  "ACTION_TYPE_6_x": "Ãvriga Ã¥tgÃ¤rder el",
  "VENTILATION_TYPE_FTX": "FTX (mekanisk frÃ¥n- och tilluft med Ã¥tervinning)",
  "VENTILATION_TYPE_FVP": "FVP (frÃ¥nluftsvÃ¤rmepump)",
  "VENTILATION_TYPE_F": "F (mekanisk frÃ¥nluftsventilation)",
  "VENTILATION_TYPE_FT": "FT (mekanisk frÃ¥n- och tilluftsventilation)",
  "VENTILATION_TYPE_S": "S (sjÃ¤lvdragsventilation)",
  "VENTILATION_TYPE_OTHER": "Annat",
  "Back to %s": "Tillbaka till %s",
  "Category": {
    "one": "Kategori",
    "other": "Kategorier"
  },
  "Date": "Datum",
  "Cost": "Kostnad",
  "Description": "Beskrivning",
  "Save": "Spara",
  "Edit energy action": "Ãndra energiÃ¥tgÃ¤rd",
  "Cancel": "Avbryt",
  "Comment": {
    "one": "%d Kommentar",
    "other": "%d Kommentarer"
  },
  "No comments yet": "Inga kommentarer Ã¤n",
  "Post": "Skicka",
  "View all %d comments": "Visa alla %d kommentarer",
  "Delete": "Ta bort",
  "Cooperative name": "FÃ¶reningens namn",
  "No energy actions": "Inga energiÃ¥tgÃ¤rder",
  "Energy use": "EnergianvÃ¤ndning",
  "Current year": "Nuvarande Ã¥r",
  "Previous year": "FÃ¶regÃ¥ende Ã¥r",
  "Compare with": "JÃ¤mfÃ¶r med",
  "Neighborhood average": "Snitt fÃ¶r kvarteret",
  "Heating & Hot water": "VÃ¤rme och varmvatten",
  "heat": "VÃ¤rme och varmvatten",
  "Electricity": "Fastighetsel",
  "electricity": "Fastighetsel",
  "elec": "el",
  "Total elec (incl. households)": "Total el (inkl. hushÃ¥llsel)",
  "%s (incl. households)": "%s (inkl. hushÃ¥llsel)",
  "%s (estimate)": "%s (osÃ¤kert vÃ¤rde)",
  "Monthly": "MÃ¥nad",
  "month": "mÃ¥nad",
  "Yearly": "Ãr",
  "year": "Ã¥r",
  "Show All Cooperatives": "Visa alla fÃ¶reningar",
  "Designated Energyrepresentative": "Energiansvarig utsedd",
  "Energy mapping": "GenomfÃ¶rd energikartlÃ¤ggning",
  "Contract for goal oriented energy management": "Har kontrakt fÃ¶r mÃ¥lstyrd energifÃ¶rvaltning",
  "Participating in belysningsutmaningen": "Deltar i belysningsutmaningen",
  "Has charger for electric cars": "Har laddare fÃ¶r elbil",
  "Charger for electric cars": "Laddare fÃ¶r elbil",
  "Has renewable energy production": "Har fÃ¶rnybar energiproduktion",
  "Renewable energy production": "FÃ¶rnybar energiproduktion",
  "Energy production": "Energiproduktion",
  "Energy representative": "Energiansvarig",
  "Consumption mapping": "EnergikartlÃ¤ggning",
  "Energy management": "MÃ¥lstyrd energifÃ¶rvaltning",
  "Belysningsutmaningen": "Belysningsutmaningen",
  "Other renewable energy production": "Annan fÃ¶renyelsebar energiproduktion",
  "Other cooperatives close by": "Andra fÃ¶reningar i nÃ¤rheten",
  "Pick one": "VÃ¤lj en",
  "Find me": "Hitta mig",
  "You are here": "Du Ã¤r hÃ¤r",
  "Show earlier": "Visa tidigare",
  "Show more recent": "Visa senare",
  "Energy actions": "Energy actions",
  "Normalize": "Korrigera fÃ¶r utomhustemperatur",
  "Showing": "Visar",
  "normalized": "korrigerad fÃ¶r utomhustemperatur",
  "for": "fÃ¶r",
  "per": "per",
  "compared with": "jÃ¤mfÃ¶rt med",
  "Edit": "Ãndra",
  "Next": "NÃ¤sta",
  "I don't have an account": "Jag har inget konto",
  "Add energy action": "LÃ¤gg till energiÃ¥tgÃ¤rd",
  "Action": "ÃtgÃ¤rd",
  "Remove enery action": "Ta bort energiÃ¥tgÃ¤rd",
  "Reuse e-mail address from registration": "AnvÃ¤nd samma epost som vid registrering",
  "Register using %s": "Registrera med %s",
  "E-mail address of energy representative": "Epostadress till energiansvarig i fÃ¶reningen",
  "Shared laundry room": "Gemensam tvÃ¤ttstuga",
  "Garage": "Garage",
  "Read more about the intiative": "LÃ¤s mer om intiativet",
  "Here": "HÃ¤r",
  "Assigned energy representative": "Energiansvarig utsedd",
  "Energy consumption mapping": "GenomfÃ¶rd energikartlÃ¤ggning",
  "Goal oriented energy management": "Kontrakt fÃ¶r mÃ¥lstyrd energifÃ¶rvaltning",
  "Part of belysningsutmaningen": "Deltar i belysningsutmaningen",
  "I agree": "Jag godkÃ¤nner",
  "Pick one or more": "VÃ¤lj en eller flera",
  "This figure may be misleading due to the coopeartive not having supplied sufficient information.": "Siffran kan vara missvisande dÃ¥ fÃ¶reningen inte angivit om hushÃ¥llsel ingÃ¥r i energianvÃ¤ndningen.",
  "Update information": "Uppdatera informationen",
  "How do households pay for their electricity?": "Hur hanteras avtal fÃ¶r hushÃ¥llsel i fÃ¶reningen?",
  "All household have individual contracts and can choose energy provider": "Alla hushÃ¥ll har egna avtal och kan sjÃ¤lva vÃ¤lja elbolag",
  "The cooperative has a contract covering all energy use": "FÃ¶reningen har avtal med ett elbolag fÃ¶r all elanvÃ¤ndning",
  "Households are charged by usage or fixed ammount": "HushÃ¥llen debiteras antingen baserat pÃ¥ undermÃ¤tning eller schablon",
  "Don't know": "Vet ej",
  "Edit details": "Ãndra uppgifter",
  "What type of ventilation is installed?": "Vilken ventilationstyp Ã¤r installerad?",
  "About the project": "About the project",
  "No data": "Data saknas",
  "Fetching data": "HÃ¤mtar data",
  "Some required fields need to be filled in or are malformatted": "Ett eller flera obligatoriska fÃ¤lt behÃ¶ver fyllas i eller Ã¤r felformaterade",
  "Type of action": "Typ av Ã¥tgÃ¤rd",
  "This field is required": "Detta fÃ¤lt Ã¤r obligatorisk och mÃ¥ste fyllas i",
  "Contractor": "UtfÃ¶rt av",
  "Energy initiatives": "Energiinitiativ",
  "ÃtgÃ¤rd": "ÃtgÃ¤rd",
  "No consumtion data": "Ingen data om energiprestanda",
  "Pick a cooperative": "VÃ¤lj en fÃ¶rening",
  "There would be a pretty chart here, had you enabled JavaScript": "HÃ¤r skulle det finnas snygga grafer om du aktiverat JavaScript",
  "METRY_ERROR": "NÃ¥got gick fel vid inloggningen. VÃ¤nligen fÃ¶rsÃ¶k igen.",
  "Unauthorized": "RÃ¤ttigheter saknas",
  "Does not have shared laundry room": "Har inte gemensam tvÃ¤ttstuga",
  "Has shared laundry room": "Har gemensam tvÃ¤ttstuga",
  "Does not have garage": "Har inte garage",
  "Has garage": "Har garage",
  "Contact regarding energy": "Kontakt fÃ¶r energifrÃ¥gor",
  "An error has occured": "NÃ¥got har gÃ¥tt fel",
  "Oops!": "Hoppsan!",
  "An unforseen error has occured. It might be temporary and you could": "NÃ¥got har gÃ¥tt fel. Det kan vara tillfÃ¤lligt och du kan",
  "try again": "fÃ¶rsÃ¶ka igen",
  "or go to": "eller gÃ¥ till",
  "the homepage": "hemsidan",
  "Meter #%s": "MÃ¤tare #%s",
  "You must agree to the terms to continue": "Du mÃ¥ste acceptera vilkoren fÃ¶r att gÃ¥ vidare",
  "This page could not be found. Try using the menu or go back to": "Sidan kunde inte hittas. AnvÃ¤nd menyn fÃ¶r att hitta rÃ¤tt eller gÃ¥ tillbaka till",
  "Page not found": "Sidan kunde inte hittas",
  "Cooperative not found": "FÃ¶reningen kunde inte hittas",
  "Some information is missing, please review and try again": "NÃ¥got saknas, vÃ¤nligen se Ã¶ver uppgifterna och fÃ¶rsÃ¶k igen",
  "Missing your cooperative?": "Finns din fÃ¶rening inte med Ã¤n?",
  "Add your cooperative": "LÃ¤gg till din fÃ¶rening",
  "Something went wrong when signing you in. Please try again.": "NÃ¥got gick fel vid inloggningen. VÃ¤nligen fÃ¶rsÃ¶k igen.",
  "Find cooperative": "Hitta fÃ¶rening",
  "Type here": "Skriv hÃ¤r",
  "Sorry, we couldn't find any cooperative by that name": "Vi kunde inte hitta nÃ¥gon fÃ¶rening med det namnet",
  "Address": "Adress",
  "Add cooperative": "LÃ¤gg till fÃ¶rening",
  "Postnummer": "Postnummer",
  "Ort": "Ort",
  "Did not recognize the address": "Vi kÃ¤nner inte igen den adressen",
  "Geothermal heating": "BergvÃ¤rme",
  "Solar panels": "Solceller",
  "Other": "Annat",
  " and ": " och ",
  "Has %s": "Har %s",
  "Disable tracking": "StÃ¤ng av spÃ¥rning",
  "Enable tracking": "SlÃ¥ pÃ¥ spÃ¥rning",
  "Search and filter": "SÃ¶k och filtrera",
  "Filter": "Filtrera",
  "Search": "SÃ¶k",
  "Select which cooperatives are shown on the map based on what they have or do not have.": "BestÃ¤m vilka fÃ¶reningar som visas pÃ¥ kartan beroende pÃ¥ vad de har eller inte har.",
  "Pick one or more energy initiatives below.": "VÃ¤lj en eller flera energiinitativ hÃ¤r nedan.",
  "Find a cooperative by searhing for it by its name.": "Hitta en fÃ¶rening genom att sÃ¶ka pÃ¥ dess namn.",
  "Reset": "ÃterstÃ¤ll",
  "Show cooperatives that have": "Visa fÃ¶reningar som har",
  "Show cooperatives that _do not_ have": "Visa fÃ¶reningar som _inte_ har",
  "Forum": "Forum",
  "Forum undefined": "Forum undefined",
  "Yes": "Ja",
  "No": "Nej",
  "Show more": "Visa mer",
  "Hide": "DÃ¶lj"
}
