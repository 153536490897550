const html = require('choo/html')

module.exports = function () {
  return html`
    <svg class="PageHead-logo" viewBox="0 0 38 31">
      <path d="M10,31 L17,31 L17,24 L21,24 L21,31 L28,31 L28,0.028870229 L10,0.028870229 L10,31 Z M20,3 L24,3 L24,7 L20,7 L20,3 Z M20,9 L24,9 L24,13 L20,13 L20,9 Z M20,16 L24,16 L24,20 L20,20 L20,16 Z M14,3 L18,3 L18,7 L14,7 L14,3 Z M14,9 L18,9 L18,13 L14,13 L14,9 Z M14,16 L18,16 L18,20 L14,20 L14,16 Z" fill="#009036" />
      <path d="M0,31 L6,31 L6,24.6627481 L9,24.6627481 L9,3 L0,3 L0,31 Z M3,6 L7,6 L7,10 L3,10 L3,6 Z M3,12 L7,12 L7,16 L3,16 L3,12 Z M3,17.9966438 L7,17.9966438 L7,21.9966438 L3,21.9966438 L3,17.9966438 Z" fill="#C8D200" />
      <path d="M29,31 L35,31 L35,24.6627481 L38,24.6627481 L38,3 L29,3 L29,31 Z M32,6 L36,6 L36,10 L32,10 L32,6 Z M32,12 L36,12 L36,16 L32,16 L32,12 Z M32,17.9966438 L36,17.9966438 L36,21.9966438 L32,21.9966438 L32,17.9966438 Z" fill="#FBBA00" transform="translate(33.500000, 17.000000) scale(-1, 1) translate(-33.500000, -17.000000) " />
    </svg>
  `
}
